.headingH2 {
    font-family: "Abel", sans-serif;
    font-size: 24px;
    font-weight:500;
    color: #FFF;
}
.breadcrumbs {
    background: #1e4356;
    color: #FFF;
    text-transform: capitalize;
    padding:0.8em 0em;
    font-family: "Abel", sans-serif;
    margin-top: 6.7em;
}

.sechead {
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 0 0 10px;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
}
.aboutH3 {
    font-weight: 400;
    font-size: 24px;
    font-family: "Francois One", sans-serif;
    color:#006aae;
    margin:15px 0px;
}

.ulList {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.ulList li {
    color: #444;
    display: block;
    padding: 5px 0;
    margin-left: 1em;
    font-family: "Abel", sans-serif;
    font-size: 20px;
}
.ulList li svg {
    color: #006aae;

}