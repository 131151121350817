.headingH2 {
    font-family: "Abel", sans-serif;
    font-size: 24px;
    font-weight:500;
    color: #FFF;
}
.breadcrumbs {
    background: #1e4356;
    color: #FFF;
    text-transform: capitalize;
    padding:0.8em 0em;
    font-family: "Abel", sans-serif;
    margin-top: 6.7em;
}

.aboutp {
    text-align: justify;
        font-family: 'Abel', sans-serif !important;
    font-size:20px;
}

.aboutH3 {
    font-weight: 400;
    font-size: 24px;
    color:#006aae;
    margin:15px 0px;
    font-family: 'Francois One', sans-serif;
}