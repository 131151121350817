@import url('http://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500&display=swap');
@import url('http://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500&display=swap');

@import url('http://fonts.googleapis.com/css2?family=Abel&family=Armata&family=Francois+One&family=Oswald:wght@500;600;700&family=Pontano+Sans&display=swap');
@font-face {
  font-family: Gautami;
  src: url('../../public/Gautami.ttf');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Abel , Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (min-width: 768px) {
  div.dropdown-menu  {
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(2rem);
    -webkit-opacity: 0;
  }
}

div.nav-item div.show {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
.section-title1 h2 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 6px;
  text-transform: uppercase;
  color: #727272;
  font-family: "Abel",sans-serif;
}
.dropdown-item.active, .dropdown-item {
  padding: 10px 20px !important;
  font-size: 14px  !important;
  text-transform: none;
  color: #1c3745;
}



.carousel-inner {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}


.carousel-caption {
  position: absolute;
  right: 15%;
  top: 9.25rem !important;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  
}
.dropdown-item {
  padding: 7px 17px !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  /* margin-top: 9px; */
  vertical-align: .255em;
  /* line-height: 27px; */
  content: "";
  /* position: absolute; */
  /* top: 20px; */
  /* padding: 10px; */
  border-top: 0.40em solid;
  border-right: .40em solid transparent;
  border-bottom: 0;
  font-size: 12px;
  border-left: .40em solid transparent;
}
.carousel-item:after {
  content:"";
  display:block;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:10px;
  width:100%;
  background:rgba(0,0,0,0.5);
}


.carousel-caption {
  z-index: 1;
}

.animate__animated {
  --animate-duration: 2s;
}


ol li.breadcrumb-item a{
  text-decoration: none;
}

.counts .count-box {
  display: flex;
  align-items: center;
  padding: 13px;
  width: 100%;
  height:100px;
  background: #fff;
  box-shadow: 0px 0 30px rgb(1 41 112 / 8%);
}

.counts .count-box span {
  font-size: 20px;
  display: block;
  font-weight: 600;
  color: #0b198f;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 13px !important;
}

.counts .count-box svg {

  line-height: 0;
  margin-right: 20px;
  color: #4154f1;
}

section.about p {
  font-family: "Francois One", sans-serif;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #006aae;
}



.about .content ul {
  list-style: none;
  padding: 0;
}

section#about  h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #42d2fd;
  margin: 4px 10px;
}


section#clients .section-title h2,section#contacts .section-title h2,section#products .section-title h2 {
  font-size: 26px;
  font-weight: 800;
  margin: 24px 0px;
  padding: 30px 0px;
  position: relative;
  font-family: "Francois One", sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #464646;
}

section#clients {
  background: #f2fdff;
}


section#clients .section-title p, section.contacts .section-title p, section.products .section-title p  {
  font-size: 18px;
  font-family: "Nunito", sans-serif;
  text-transform: none;
  font-weight: 600;
}

section#products {
  background: #f8f9fa;
}

.align-items-stretch {
  align-items: stretch!important;
}

.d-flex {
  display: flex!important;
}
div.member-img img {
  height: 100%;
    width: 100%;
    object-fit: contain;
}
.products .member .member-info {
  padding: 25px 15px;
  font-family: 'Poppins', sans-serif;

}

div.swiper-button-next, div.swiper-button-prev{
  color: #ffffff;
    background: #00adffcf;
    padding: 23px;
    border-radius: 5px;
    font-weight: 900;
}
div.swiper-button-prev::after, div.swiper-button-next::after{
 font-size: 18px;
}
div.swiper-button-prev{
  left:20px !important;
  top:43%;
}
div.swiper-button-next{
  right:20px !important;
  top:43%;
}
.fixed-top {
  top: 31px !important;
}
.products .member .member-info h4 {
   

  display: block;
  display: -webkit-box;
  max-width: 100%;
  font-size: 20px;
  /* color: #098ccb; */
  height: 75px;
  font-family: "Francois One", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
/* font-family: "Abel", sans-serif;
font-weight: bold; */
 }

.products .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 7%);
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}


.about .content ul svg {

  font-size: 20px;
  color: #084298;
  line-height: 1;
}



section#clients  h2::after, section.contacts  h2::after, section.products h2::after{
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #00adff;

  bottom: 0;
  left: calc(50% - 25px);
}

#contacts h2 {
  font-size: 26px;
  font-weight: 800;
  margin: 13px 0px;
  padding: 17px 0px;
  position: relative;
  font-family: "Francois One", sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}

#contacts h2::after{
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #00adff;

  bottom: 0;
  left: calc(50% - 25px);
}
nav[aria-label="breadcrumb"]{
  margin-top:5px;
}
ol.breadcrumb li {
  line-height: 33px;
}

section.contact .php-email-form {
  box-shadow: 0 0 30px rgb(214 215 216 / 60%);
  padding: 30px;
  background: #fff;
}

section.contacts .info {
  border-top: 3px solid #47b2e4;
  border-bottom: 3px solid #47b2e4;
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%);
}

section.contacts .php-email-form {
  width: 100%;
  border-top: 3px solid #47b2e4;
  border-bottom: 3px solid #47b2e4;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 12%);
}

.contacts .php-email-form button[type=submit]:hover {
  background: #209dd8;
}

.contacts .php-email-form button[type=submit] {
  background: #47b2e4;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}


.contacts .php-email-form input, .contacts .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.contacts .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #37517e;
}


.contacts .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #6182ba;
}
.contacts .info svg {
  font-size: 15px;
  color: #47b2e4;
  margin-right: 15px;
  float: left;
  width: 38px;
  height: 38px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}


div.card {
  width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 10%);
    padding: 30px 20px;
    background: #fff;
    margin: 10px;
}




.slick-list {margin: 0 -5px;}
.slick-slide>div {padding: 0 5px;}
.carousel-item:after {
  content:"";
  display:block;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:10px;
  width:100%;
  background:rgba(0,0,0,0.5);
}


.slick-prev:before, .slick-next:before {
  font-family: 'slick';
    font-size: 32px !important;
    line-height: 1;
    opacity: 1 !important;
    color: #cecece !important;
}



.slick-prev {
  left: -36px !important;
}



.slick-slide {
  margin: 0 27px;
}
/* the parent */
.slick-list {
  margin: 0 -27px;
}
.swiper-slide {
  text-align: center !important;
}
/* 

@media only screen and (max-width: ){
  
} */

@media only screen and (max-width: 600px) {

  .products .member .member-info h4 {
    height: 60px;
    margin: 0 auto;
    font-size: 14px;
    }
  
}

.form-control {
  display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;


    background-clip: padding-box;
    border: 1px solid #f8f9fa !important;
    margin: 10px 0px;
}

.scrolltop {
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00adff;
  padding: 9px 11px;
  color: #FFF;
  cursor: pointer;
}

.form-control:focus {
 
 
  background-color: #f8f9fa !important;
  border-color: #676767 !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}




.logoMarqueeSection {

}

#logoMarqueeSection {
  max-width: 1920px!important;
  margin: 0 auto;
}

.default-content-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    width: 100%;
    min-height: 100vh;
}

div.marquee>a>img {
  height: 120px;
}
.text-start{
  margin-bottom:0px;
  padding:5px;
}

.logoMarqueeSection>div>div {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
}


.marquee {
    display:  inline-block;
    white-space: nowrap;
    position: relative;
    transform: translate3d(0%, 0, 0);
    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.marquee a {
    display:  inline-block;
    white-space: nowrap;
    padding-right: 1.4rem;
}
.display-3   {
  color:#FFF;
}

.marquee-wrapper:hover .marquee {
    animation-play-state: paused !important;
}

@keyframes marquee {
    0% {
        transform: translate3d(0%, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}
@media only screen and (min-width: 768px) {}


@media only screen and (min-width: 992px) {}
@media only screen and (max-width: 480px) {
  .carousel-caption {
    top: 5.25rem !important;
    font-size: 12px !important;
    }
    .display-3   {
      font-size: 18px  !important;
      font-weight: 800 !important;
      margin-bottom: 12px;
    }
    .carousel-inner {
      height: auto !important;
    }
}

@media only screen and (min-width: 1200px) {}


.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #d9edff;
}
.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0,0,0,0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}
#login-form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;
}
#login-form p {
  margin-bottom: 30px;
}
#login-form p.form-title {
  color: #333333;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}
#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-control-input-content {
  text-align: left;
}
#login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}
#login-form_username {
  height: 48px;
}
#login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}
.login-form-button {
  width: 100%;
}
.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}
.illustration-wrapper img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #login-form {
    max-width: 100%;
  }
}

@import '~antd/dist/antd.css';


#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

aside.ant-layout-sider {
  height: 100vh;
}