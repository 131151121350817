.logoNav {
    display:inline-block;
  }
.logoText{
  display: inline-block;
  color: #006aae !important;
    font-weight: 600;
    /* font-family: 'Encode Sans', sans-serif; */
    /* font-family: 'Manrope', sans-serif; */
    font-family: 'Archivo', sans-serif;
}
.autoScroll {
  max-height: 261px !important;
    overflow-y: scroll !important;
}
.autoScroll::-webkit-scrollbar {
	width: 8px;
}
.autoScroll::-webkit-scrollbar-track {
	background: #c1c0c0;
}
.autoScroll::-webkit-scrollbar-thumb {
	background: #828282;
}
.autoScroll::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.collasible-nav-dropdown-annual{

}
.navbarStyle  {
  font-family: 'Abel', sans-serif;
  font-size:18px;
}
#anchorAnnual{
  padding-left: 16px !important;
  padding-right: 25px;
    color: #000;
    font-size:18px  !important;
}
#anchorAnnual:hover{
  color: #1e2125 !important;
    background-color: #e9ecef !important;

}
#anchorAnnual::after{
  right: 9px;
  position: absolute;
  top: 17px;
}
.logoImage {
  width:60px;
}

@media only screen and (max-width: 480px ) {
  .logoText{
    font-size:16px;
  }
  .logoImage {
    width:30px;
  }
}

  .logoNav img,.logoNav h4  {
    display:inline;
    display: inline;
    font-size: 20px;
    /* line-height: 33px; */
    text-transform: uppercase;

    font-family: Gautami,sans-serif;

  }


  @media (min-width: 768px) {
    .animate {
      animation-duration: 0.3s;
      -webkit-animation-duration: 0.3s;
      animation-fill-mode: both;
      -webkit-animation-fill-mode: both;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0rem);
      opacity: 1;
    }
  
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes slideIn {
    0% {
      -webkit-transform: transform;
      -webkit-opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(0);
      -webkit-opacity: 1;
    }
  
    0% {
      -webkit-transform: translateY(1rem);
      -webkit-opacity: 0;
    }
  }
  
  .slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
  }