.headingH2 {
    font-family: "Abel", sans-serif;
    font-size: 24px;
    font-weight:500;
    color: #FFF;
}

.imgDiv {
    height: 250px;
    overflow: hidden;

}
.breadcrumbs {
    background: #1e4356;
    color: #FFF;
    text-transform: capitalize;
    padding:0.8em 0em;
    font-family: "Abel", sans-serif;
    margin-top: 6.7em;
}

.sechead {
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 0 0 10px;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
    font-family: "Abel", sans-serif;
}
.ulList {
    list-style: outside none none;
    font-family: "Abel", sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
}
.ulList li {
    color: #444;
    display: block;
    padding: 5px 0;
    margin-left: 1em;
}
.ulList li svg {
    color: #00adff;

}
.aboutp {
    text-align: justify;
        font-family: 'Abel', sans-serif !important;
    font-size:20px;
}
.thumbNail {
    display:inline-block;
    float: left;
    
margin:5px;
    width:  200px;
    height: 200px;
}
.overflowImg {

    background-size: cover;
    

    width:  200px;
    height: 200px;
}
.aboutH3 {
    font-weight: 400;
    font-size: 24px;
    color: #006aae;
    margin:15px 0px;
    font-family: 'Francois One', sans-serif;
}