.headingH2 {
    font-family: "Abel", sans-serif;
    font-size: 24px;
    font-weight:500;
    color: #FFF;
}
.breadcrumbs {
    background: #1e4356;
    color: #FFF;
    text-transform: capitalize;
    padding:0.8em 0em;
    font-family: "Abel", sans-serif;
    margin-top: 6.7em;
}
.resumeItem ::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #0563bb;
}

.resumeItem {
    padding: 0 0 0px 26px;
    margin-top: -2px;
    border-left: 2px solid #0563bb;
    position: relative;
}

.aboutH3 {
    font-weight: 400;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    color: #006aae;
    margin:15px 0px;
}
.panelcard {
    height:25px;
}

.resume .resumeItem h4 {
    line-height: 18px;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Francois One", sans-serif;
    color: #0563bb;
    margin-bottom: 10px;
}
.resumeItem p {
    margin-bottom:0px;
    font-family: "Abel", sans-serif;
    font-size: 20px;
}
.resumeTitle {
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #45505b;
}
.main h2:after, 
.main h3:after, 
.main h4:after {
	position: absolute;
	content: "";
	left: 0;
	top: 0;
	bottom: 0;
	width: 5px;
	border-radius: 2px;
	box-shadow: 
		inset 0 1px 1px rgba(0,0,0,0.5), 
		0 1px 1px rgba(255,255,255,0.3);
}

.main h2:after { background: #0AF; }
.main h3:after { background: #3BF; }
.main h4:after { background: #6Cf; }

.main h1 {
	font-size: 36px;
	line-height: 40px;
	margin: 1em 0 .6em 0;
	font-weight: normal;
	color: white;
	font-family: 'Nunito', sans-serif;
	text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
	position: relative;
	color: #6Cf;
}

.main h2 {
    margin: 1em 0 .6em 0;
    padding: 10px 0 10px 20px;
    background: #f3f3f3;
    font-weight: 300;
    color: #484848;
    font-family: 'Nunito', sans-serif;
    border: 1px solid #f1efef;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
    position: relative;
    font-size: 20px;
    text-transform: capitalize;
}

.main h3 {
	margin: 1em 0 .6em 0;
	padding: 0 0 0 20px;
	font-weight: normal;
	color: white;
	font-family: 'Nunito', sans-serif;
	text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
	position: relative;
	font-size: 24px;
	line-height: 40px;
	font-family: 'Questrial', sans-serif;
}

.main h4 { 
	margin: 1em 0 .6em 0;
	padding: 0 0 0 20px;
	font-weight: normal;
	color: white;
	font-family: 'Nunito', sans-serif;
	text-shadow: 0 -1px 0 rgba(0,0,0,0.4);
	position: relative;
	font-size: 18px;
	line-height: 20px;
	font-family: 'Questrial', sans-serif;
}