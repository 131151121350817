.headingH2 {
    font-family: "Abel", sans-serif;
    font-size: 24px;
    font-weight:500;
    color: #FFF;
}
.breadcrumbs {
    background: #1e4356;
    color: #FFF;
    text-transform: capitalize;
    padding:0.8em 0em;
    font-family: "Abel", sans-serif;
    margin-top: 6.7em;
}
.card-block {
    font-size: 1em;
    position: relative;
    margin: 0;
    padding: 1em;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, .1);
    box-shadow: none;

}

 .pHead{
    font-family: 'Francois One', sans-serif !important;
}
.pHeadS {
    font-family: 'Francois One', sans-serif !important;
    color: #6a6a6a;
    font-size: 14px;
}
.pDescr{
    font-family: "Abel", sans-serif !important;
    font-size: 20px !important;
    text-align: justify !important;
}
.card {
    font-family: 'Nunito', sans-serif;

    overflow: hidden;
    padding: 5;
    border: none;
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    margin-top:20px;
}



.sechead {
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 0 0 10px;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
    
}


.aboutp {
    text-align: justify;
        font-family: 'Abel', sans-serif !important;
    font-size:20px;
}

.aboutH3 {
    font-weight: 400;
    font-size: 24px;
    color: #006aae;
    margin:15px 0px;
    font-family: 'Francois One', sans-serif;
    display: inline-block;
}
.theadStyle{
    background: #1e4356;
    color: #FFF;
  
}
.theadStyle th {
    padding: 10px;
}
.textInfo {
    color: #006aae;
    font-size: 1.25rem;
}